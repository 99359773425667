import React from 'react'
import { Provider } from 'react-redux'
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store'
import Theme from 'components/template/Theme'
import Layout from 'components/layout'
import mockServer from './mock'
import appConfig from 'configs/app.config'
import './locales'
import { QueryClientProvider } from 'react-query'
import { queryClient } from './services/query_client'
import { LoadScript } from '@react-google-maps/api'

const environment = process.env.NODE_ENV

function App() {
  if (appConfig.enableMock) {
    mockServer({ environment })
  }

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="*" element={
        <Theme>
          <LoadScript googleMapsApiKey={appConfig.googleApiKey} libraries={['places']}>
            <Layout />
          </LoadScript>
        </Theme>
      } />
    ),
    { basename: process.env.PUBLIC_URL }
  )
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <RouterProvider router={router} />
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  )
}

export default App
