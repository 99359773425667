import ApiService from './ApiService'
import appConfig from '../configs/app.config'

export const apiSignIn = ({ email, password }) => ApiService.fetchData({
    url: `${appConfig.apiRoot}/KIC/auth/Login`,
    method: 'post',
    params: { email, password }
})

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: '/sign-up',
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/sign-out',
        method: 'post',
        data,
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data,
    })
}
