import axios from 'axios'
import appConfig from 'configs/app.config'
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from 'constants/api.constant'
import { PERSIST_STORE_NAME } from 'constants/app.constant'
import deepParseJson from 'utils/deepParseJson'
import store from '../store'
import { onSignOutSuccess } from '../store/auth/sessionSlice'

const unauthorizedCode = [401]
axios.defaults.paramsSerializer = (params) => {
  // Sample implementation of query string building
  return Object.keys(params)
    .map((key) => {
      return `${key}=${encodeURIComponent(params[key])}`
    })
    .join('&')
}

const BaseService = (params = {}) => {
  const axiosInstance = axios.create({
    timeout: 60000,
    baseURL: appConfig.apiPrefix,
  })
  axiosInstance.interceptors.request.use(
    (config) => {
      const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
      const persistData = deepParseJson(rawPersistData)

      let accessToken = persistData.auth.session.token

      if (!accessToken) {
        const { auth } = store.getState()
        accessToken = auth.session.token
      }
      if (accessToken) {
        config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`
        config.params = config.params || {}
        if (!config.withoutAddedParams) {
          config.params.user =
            config.params.user ?? persistData.auth.user.userID
          config.params.userID = config.params.userID ?? config.params.user
          config.params.userId = config.params.userId ?? config.params.user
          config.params.licenseKey = accessToken
          config.params.license_key = config.params.licenseKey
          config.params.licenseKeyString = config.params.licenseKey
        }
      }

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const { response } = error
      if (response && unauthorizedCode.includes(response.status)) {
        store.dispatch(onSignOutSuccess())
      }

      return Promise.reject(error)
    }
  )

  return axiosInstance(params)
}

export default BaseService
