import { ADMIN, DRIVER, SERVICE, USER } from '../constants/roles.constant'

const appConfig = {
  apiRoot: window.apiRoot,
  apiPrefix: '/api',
  authenticatedEntryPath: {
    [ADMIN]: '/app/rental',
    [USER]: '/app/rental',
    [DRIVER]: '/app/driver',
    [SERVICE]: '/app/service',
  },
  unAuthenticatedEntryPath: '/sign-in',
  tourPath: '/app/account/kyc-form',
  locale: 'en',
  enableMock: true,
  googleApiKey: 'AIzaSyBMfmq3cUcZ8_f_-fxjgRaukcLgTGxWH4E',
  S3: window.S3,
  storage: window.storage,
}

export default appConfig
